export function cookieConfigurationEvent(configuration) {
  return {
    event: 'consent_changed',
    ...cookieConfiguration(configuration)
  }
}

export function cookieConfiguration({ permissions }) {
  return {
    ...permissions,
  }
}
